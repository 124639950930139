export const formatFax = function (num) {
  try {
    num = num.replace(/(\d\d\d)(\d\d\d)(\d\d\d\d)/, '($1) $2-$3');
  } catch (e) {
    console.error(e);
  }
  return num;
};

export function makePhoneNumber(faxModel) {
  var newTxt = faxModel.replace(/\D+/g, '');
  try {
    if (newTxt.length < 4) {
      newTxt = newTxt.replace(/(\d\d\d)/, '($1)');
    } else if (newTxt.length === 4) {
      newTxt = newTxt.replace(/(\d\d\d)(\d)/, '($1) $2');
    } else if (newTxt.length === 5) {
      newTxt = newTxt.replace(/(\d\d\d)(\d\d)/, '($1) $2');
    } else if (newTxt.length === 6) {
      newTxt = newTxt.replace(/(\d\d\d)(\d\d\d)/, '($1) $2');
    } else if (newTxt.length === 7) {
      newTxt = newTxt.replace(/(\d\d\d)(\d\d\d)(\d)/, '($1) $2-$3');
    } else if (newTxt.length === 8) {
      newTxt = newTxt.replace(/(\d\d\d)(\d\d\d)(\d\d)/, '($1) $2-$3');
    } else if (newTxt.length === 9) {
      newTxt = newTxt.replace(/(\d\d\d)(\d\d\d)(\d\d\d)/, '($1) $2-$3');
    } else {
      newTxt = newTxt.substr(0, 10);
      newTxt = newTxt.replace(/(\d\d\d)(\d\d\d)(\d\d\d\d)/, '($1) $2-$3');
    }
  } catch (e) {
    console.error(e);
  }
  return newTxt;
}

export function makeNumbersOnly(faxModel) {
  var newTxt = faxModel.replace(/\D+/g, '');
  return newTxt;
}

export function makeLettersOnly(zipModel) {
  var newTxt = zipModel.replace(/[^a-z ']+/gi, '');
  return newTxt;
}

export function validateEmail(email) {
  // eslint-disable-next-line no-useless-escape
  const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(email);
}

export const trackAppEvent = (eventName, pageName, searchInfo = {}, sendInfo = {}, exitURL) => {
  if (typeof window.aadata === "undefined") {
    window.aadata = [];
  }
  window.aadata.push({
    "event": eventName,
    "pageInfo": {
      "pageName": pageName
    },
    "exitInfo": {
      "exitURL": exitURL
    },
    "searchInfo": {
      "serviceType": searchInfo.serviceType,
      "country": searchInfo.country,
      "sid": searchInfo.country === "CA" ? searchInfo.sid : null,
      "resultsReturned": searchInfo.resultsReturned
    },
    "sendInfo": {
      "serviceType": sendInfo.serviceType,
      "deliveryMethod": sendInfo.deliveryMethod,
      "vendorDomain": sendInfo.email
    }
  });
};
