import axios from 'axios';

class Service {
  constructor() {
    const service = axios.create();
    this.service = service;
    this._handleError = this._handleError.bind(this);
    this._handleSuccess = this._handleSuccess.bind(this);
  }

  _get(path, params) {
    return this.service.get('/api' + path, {
      params: {...params, t: Date.now()}, timeout: 120000, headers: {mode: 'no-cors', credentials: 'include'}
    })
      .then((response) => {
        return this._handleSuccess(response);
      }).catch((error) => {
        return this._handleError(error);
      });
  };

  _post(path, payload, params) {
    return this.service.request({
      method: 'POST',
      url: '/api' + path,
      responseType: 'json',
      params: params,
      data: payload,
      timeout: 120000
    }).then((response) => {
      return this._handleSuccess(response);
    }).catch((error) => {
      return this._handleError(error);
    });
  }

  _handleSuccess(response) {
    if (response.status === 200) {
      return response.data;
    }
  }

  _handleError(error) {
    return Promise.reject(error && error.response && error.response.data ? error.response.data : 'Error');
  }

}

export default new Service();
