/* istanbul ignore file */
import React from 'react';
import ReactDOM from 'react-dom';
import '@tuxedo/bootstrap-theme/css/tuxedo-bootstrap4-theme.css';
import '@tuxedo/bootstrap-theme/css/tuxedo-components.css';
import '@tuxedo/bootstrap-theme/css/tuxedo-icons.css';
import './App.scss';
import App from './App';

ReactDOM.render(<App />, document.getElementById('root'));
